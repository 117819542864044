import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SmallBuilders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first: "",
      last: "",
      help: "",
      company: "",
      telephone: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }
  sendMessage = (event) => {
    event.preventDefault();
    if (this.state.email.length === 0 && this.state.telephone.length === 0) {
      // alert.show("phone or email required");
      toast.error("Phone or email required", {
       position: "top-center",
       autoClose: false,
       hideProgressBar: true,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
     });
       return;
     }
    const apiUrl = "https://birk.zendesk.com/api/v2/requests.json";
    const formData = {
      request: {
        requester: { name: `${this.state.first} ${this.state.last}` },
        subject: "Builder",
        comment: {
          body: `Email:${this.state.email} , Company:${this.state.company} , Message:${this.state.help}`,
        },
      },
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // BODY: {"request": {"requester": {"name": "iTechNotion customer"}, "subject": "Help!", "comment": {"body": "My printer is on fire!" }}}

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    fetch(apiUrl, options, `Jrf7BjvmmAs4sSEGmyiHk9c5SCvtwuk3e6c5Sh1u`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            email: this.state.email,
            first: this.state.first,
            last: this.state.last,
            help: this.state.help,
            company: this.state.company,
            telephone: this.state.telephone,
          });
          toast.success(
            "We have received your message and  We will get back to you soon",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
            }
          );
        },
        (error) => {
          this.setState({ error });
          toast.error("Something went wrong. Please try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      );
    this.setState({
      email: "",
      first: "",
      last: "",
      help: "",
      company: "",
      telephone: "",
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Builders Contact Form | Melbourne | Brisbane | Birk</title>
          <meta
            property="og:title"
            content="Builders Contact Form | Melbourne | Brisbane | Birk"
          />
          <meta
            name="keywords"
            content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
          />
          <meta
            name="description"
            content="Birk offers best of quality services with more than 30 year experience with builders. We are operated in Melbourne and Brisbane"
          />
          <meta
            property="og:description"
            content="Birk offers best of quality services with more than 30 year experience with builders. We are operated in Melbourne and Brisbane"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="http://birk.itechnotion.com/builders"
          />
          <link rel="canonical" href="http://birk.itechnotion.com/builders" />
        </Helmet>
        <Header />
        <ToastContainer />
        <section className="product-section">
          <div className="container-fluid all-section">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="heading">
                  <h2>Partner with us</h2>
                </div>
                <div className="product-menu">
                  <ul>
                    <li className="mb-2">
                      <Link to="/developer">Developers</Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/builders">
                        <span className="font-weight-bold">Builders</span>
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/architect-designer">
                        Architects & Designers
                      </Link>
                    </li>
                  </ul>
                  <div className="links-list sidebar-links">
                    <Link to="/service" className="links-list-item">
                      <FaMicrosoft />
                      &nbsp; Go to services
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9 product-item mt-2">
                <div className="col-12 col-md-12 mb-4">
                  <div className="pro-start-content">
                    <div className="product-inner">
                      <p className="text-justify">
                        As a builder, you can be confident that we’ll deliver a
                        window furnishings solution that meets your standard and
                        eye for detail. Our innovative approach to every aspect
                        of the supply chain means we can deliver a superior,
                        highly durable product at a competitive price.
                      </p>
                      <p className="text-justify">
                        Get in touch today to discuss window furnishings for
                        your next build.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-12">
                  <div className="about-form">
                    <form onSubmit={this.sendMessage}>
                      <div className="form-group">
                        <label
                          htmlFor="about-company"
                          className="font-weight-bold"
                        >
                          Company<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-company"
                          name="company"
                          value={this.state.company}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-First"
                          className="font-weight-bold"
                        >
                        Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-First"
                          name="first"
                          value={this.state.first}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        />
                      </div>
                      {/* <div className="form-group">
                        <label
                          htmlFor="about-Last"
                          className="font-weight-bold"
                        >
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-Last"
                          name="last"
                          value={this.state.last}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        />
                      </div> */}
                      <div className="form-group">
                        <label
                          htmlFor="about-Email"
                          className="font-weight-bold"
                        >
                          Phone Number
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about-Phone"
                          name="telephone"
                          value={this.state.telephone}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-Email"
                          className="font-weight-bold"
                        >
                          Email
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="about-Email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="about-Help"
                          className="font-weight-bold"
                        >
                          How Can We Help?<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="about-Help"
                          rows="5"
                          name="help"
                          value={this.state.help}
                          onChange={this.handleInputChange.bind(this)}
                          required
                        ></textarea>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-dark text-uppercase"
                      >
                        submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}
export default SmallBuilders;
